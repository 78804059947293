import React from "react";
import { Link } from "react-router-dom";
import LogOutModal from "../subcomponents/LogOutModal";

function DashboardBottomBar(props) {
  const emailId = localStorage.getItem("useremail");
  const role = localStorage.getItem("role");
  const firstName=localStorage.getItem('firstname')
  const lastName=localStorage.getItem('lastname')
  return (
    <div className="Dashboard_bottom_bar_whole_wrapper">
      <ul className="Dashboard_bottomBar_ul_wrapper">
        <Link
          to="/"
          style={{ textDecoration: "none" }}
          className="Dashboard_side_bar_links"
        >
          <li className={props.active === "" ? "SideBar_active_two" : null}>
            <img
              style={{ width: "auto", height: "50px" }}
              src="/assets/images/OttonomyLogo.png"
              className="logo-btm"
            />
          </li>{" "}
        </Link>

        <Link
          to="/dashboard"
          style={{ textDecoration: "none" }}
          className="Dashboard_side_bar_links"
        >
          <li
            className={props.active === "dashboard" ? "SideBar_active_two" : null}
          >
            <span>
              <img
                className="Dashboard_SideBar_icon_one"
                src="/assets/images/Dashboard icon selected.svg"
              />{" "}
            </span>
            <p style={{ marginBottom: "0px" }} className="Dashboard_side_bar_P">
              Dashboard
            </p>
          </li>{" "}
        </Link>
        <Link
          to="/fleetPage"
          style={{ textDecoration: "none" }}
          className="Dashboard_side_bar_links"
        >
          <li
            className={props.active === "fleetpage" ? "SideBar_active_two" : null}
          >
            <span style={{ width: "25%" }}>
              <img
                className="Dashboard_SideBar_icon"
                style={{ width: "auto", height: "35px" }}
                src="/assets/images/Fleeticon.svg"
              />{" "}
            </span>
            <p style={{ marginBottom: "0px" }} className="Dashboard_side_bar_P">
              {" "}
              Fleets
            </p>
          </li>
        </Link>
        <Link
          to="/robotpage"
          style={{ textDecoration: "none" }}
          className="Dashboard_side_bar_links"
        >
          <li
            className={props.active === "robotpage" ? "SideBar_active_two" : null}
          >
            <span style={{ width: "25%" }}>
              <img
                className="Dashboard_SideBar_icon"
                style={{ width: "auto", height: "35px" }}
                src="/assets/images/Robot_icon_sidebar.svg"
              />
            </span>
            <p style={{ marginBottom: "0px" }} className="Dashboard_side_bar_P">
              {" "}
              Robots
            </p>
          </li>
        </Link>
        {/* {role === "Admin" || role === "Super Admin" ? (
          <Link
            to="/userlist"
            style={{ textDecoration: "none" }}
            className="Dashboard_side_bar_links"
          >
            <li
              className={props.active === "userlist" ? "SideBar_active_two" : null}
            >
              <span style={{ width: "25%" }}>
                <img
                  className="Dashboard_SideBar_icon"
                  style={{ width: "100%" }}
                  src="/assets/images/Client_icon.svg"
                />
              </span>
              <p
                style={{ marginBottom: "0px" }}
                className="Dashboard_side_bar_P"
              >
                Users
              </p>
            </li>
          </Link>
        ) : null} */}
        <li>
          <LogOutModal />
          <p className="SideBar_name_p">{firstName} {lastName}</p>
          <p
              className="SideBar_name_p"
              style={{ opacity: "0.7", fontSize: "11px" }}
            >
              {" "}
              {role}
            </p>
        </li>
      </ul>
      {/* <div className='name-log' style={{margin:'0 20px 15px', position:'relative'}}> <div> 
        <p className="SideBar_name_p">{emailId}</p>
        <p className='SideBar_name_p' style={{opacity:'0.7', fontSize:'11px'}}> {role}</p>
        <LogOutModal/>
        </div>
        </div> */}
    </div>
  );
}

export default DashboardBottomBar;
