import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getorderstatus, orderBook, orderlist } from "../API";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import { useCallback } from "react";
import axios from "axios";
import { useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Loader from "./Loader";

function OrderList(props) {
  const [Paginationlength, setPaginationlength] = useState(false);
  const [page, setpage] = useState(1);
  const [orderModalOpen, setorderModalOpen] = React.useState(false);
  const [showcount, setshowcount] = useState();
  const [orderlistData, setOrderlistData] = useState();
  const [refresh, setrefresh] = useState(false);
  const { fleetList } = useSelector((state) => state.fleetList);
  const [currentpage, setcurrentpage] = useState(1);
  const isopen = useSelector((state) => state.dashboardopenReducer);
  const [orderaccepted, setorderaccepted] = useState(false);
  const [loader, setLoader] = useState(false);
  const size = "10";
  const token = localStorage.getItem("token");

  const updateState = useCallback(async () => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}order/list`,{
      fleetId: props?.fleetData?.fleetId,
      page: page,
      pageSize:size
   },{
       headers:{
            'Authorization':`Bearer ${token}`,
           'Content-Type': 'application/json', 
       }
   })
   .then((res)=>{
      // setTotalOrders(res.data.data.count)
      // setorderlist(res.data.data.order)
      // console.log('resresres',res)
      setOrderlistData(res.data?.data?.order)
       if(res.data?.data?.count>size){
        setPaginationlength(true)
        // setPaginationlength(Paginationlength => Paginationlength+1)
      }
     
      if(res?.data?.data?.count%1===0){
        setshowcount(Math.floor(res?.data?.data?.count/10)+1)
        // console.log('res.data.data.count%1',res.data.data.count%1)
        // console.log('showcount',showcount)
      }
      else{
        setshowcount(res?.data?.data?.order?.length/10)
      }
   })

        // if (res?.data?.count % 1 === 0) {
        //   setshowcount(Math.floor(res?.data?.count / 10) + 1);
        //   // console.log('res.data.data.count%1',res.data.data.count%1)
        //   // console.log('showcount',showcount)
        // } else {
        //   setshowcount(res?.data?.order?.length / 10);
        // }
   
  }, [currentpage, size]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();
    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useIntervalAsync(updateState, 5000);
  useEffect(() => {
    setorderaccepted(true);
    setrefresh(!refresh);
  }, []);

  // useEffect(() => {
  //   orderlist(fleetList[0]?.fleetId, page, size, token)
  //     .then((res) => {
  //       setOrderlistData(res.data.data.order);

  //       if (res.data.data.count > 10) {
  //         setPaginationlength(true);
  //       }

  //       if (res.data.data.count % 1 === 0) {
  //         setshowcount(Math.floor(res.data.data.count / 10) + 1);
  //       } else {
  //         setshowcount(res.data.data.count / 10);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("OrderList list response", err);
  //     });
  // }, [refresh, page, fleetList, showcount, token]);

  // const handleChange = (event, value) => {
  //   setpage(value);
  //   orderlist(fleetList[0].fleetId, page, size, token)
  //     .then((res) => {
  //       setOrderlistData(res.data.data.order);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const handleChangetwo = (event, value) => {
    setpage(value);
    setcurrentpage(value);
  };
  const modalstyle1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "#3D4665",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const validationSchema = yup.object({
    orderId: yup.string().required("Order Id is required").max(11, "OrderId must not be more than 11 digits."),
    orderSize: yup.string().required("Order Size is Required"),
    deliveryLocation: yup.string().required("Delivery Location is required"),
  });

  const formik = useFormik({
    initialValues: {
      orderId: "",
      orderSize: "",
      deliveryLocation: "",
      height: "",
      width: "",
      length: "",
      weight: "",
      longitutde: "",
      latitude: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values,{resetForm}) => {
      console.log(values)
      orderBook(values, fleetList[0]?.fleetId, token).then((res) => {
        if(res.data.status === "B013") {
          setLoader(false);
          setorderModalOpen(false);
          toast.success(res.data.message)
          resetForm({values:''})
        } else {
          setLoader(false);
          setorderModalOpen(false);
          throw new Error(res.data.message)
        }
      }).catch(err => {
        console.error(err);
        setLoader(false);
        setorderModalOpen(false);
        toast.error(err.message)
      });
      setLoader(true);
    },
  });
  const assignOrder = (event) => {
    setorderModalOpen(true);
  };
  const orderModalClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    setorderModalOpen(false);
  };

  const orderStatusColor=(orderStatus , statusCode)=>{
    switch(orderStatus){
      case "order delivered":
       return <p className="p-color-green orderStatus_p" >{orderStatus} {statusCode && <>({statusCode})</>}</p> 
      case "Order is Cancelled": 
       return <p className="p-color-red orderStatus_p"> {orderStatus}{statusCode && <>({statusCode})</>}</p> 
      case "order not confirmed":
       return <p className="p-color-yellow orderStatus_p"> {orderStatus}{statusCode && <>({statusCode})</>}</p> 
       default:
        return <p className="p-color-blue orderStatus_p"> {orderStatus}{statusCode && <>({statusCode})</>}</p> 
    }
  }
  return (
    <>
      {process.env.REACT_APP_ALLOW_ORDERING_ARAMEX && (
        <>
          <button className="btn Assign-btn" onClick={assignOrder}>
            Book New Order
          </button>
          <Modal
            open={orderModalOpen}
            onHide={orderModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            backdrop="static"
            keyboard={false}
          >
            <Box sx={modalstyle1}>
              {loader ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader />
                  <span style={{ color: "white" }}>Booking Order...</span>
                </div>
              ) : (
                <div className="Order_modal_wrapper">
                  <h3 className="Order_modal_h3"> Book an Order</h3>
                  <div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="AddFleet_form_field_wrapper addlocation_textfield form__control__wrapper">
                        <TextField
                          id="standard-size-normal"
                          fullWidth
                          variant="standard"
                          value={formik.values.orderId}
                          name="orderId"
                          onChange={formik.handleChange}
                          placeholder="Order Id"
                          style={{ fontSize: "14px" }}
                          error={
                            formik.touched.orderId &&
                            Boolean(formik.errors.orderId)
                          }
                          helperText={
                            formik.touched.orderId && formik.errors.orderId
                          }
                        />
                        <FormControl
                          fullWidth
                          style={{
                            backgroundColor: "rgb(28, 30, 43)",
                            WebkitTextFillColor:
                              "rgb(189, 196, 224) !important",
                          }}
                        >
                          {/* <InputLabel
                            id="orderSize-label"
                            style={{
                              WebkitTextFillColor:
                                "rgb(189, 196, 224) !important",
                            }}
                          >
                            Order Size
                          </InputLabel> */}
                          <Select
                            variant="standard"
                            labelId="orderSize-label"
                            id="orderSize-select"
                            name="orderSize"
                            value={formik.values.orderSize === "" ? "Select Order Size" : formik.values.orderSize}
                            label="orderSize"
                            style={{
                              color: "rgb(189, 196, 224)",
                              margin: "0",
                              padding: "5px 5px 5px 10px",
                              fontSize: "14px",
                            }}
                            error={
                              formik.values.orderSize === "" &&
                              Boolean(formik.errors.orderSize)
                            }
                            // renderValue={formik.values.orderSize === "" && "Select Order Size"}
                            // style={{ borderBottom: "1px solid blue"}}
                            //   onChange={(event,newvalue)=>{
                            //     formik.setFieldValue('orderSize',event.target.value)
                            // }}
                            onChange={formik.handleChange}
                          >
                            <MenuItem
                              value="Select Order Size"
                              selected
                              disabled
                            >
                              Select Order Size
                            </MenuItem>
                            <MenuItem
                              value={`{"height": 10, "length": 10, "width": 10, "weight": 10}`}
                            >
                              Small Cabin
                            </MenuItem>
                            <MenuItem
                              value={`{"height": 25, "length": 25, "width": 25, "weight": 25}`}
                            >
                              Large Cabin
                            </MenuItem>
                            <MenuItem value={`Custom`}>Custom</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl
                          fullWidth
                          style={{
                            backgroundColor: "rgb(28, 30, 43)",
                            WebkitTextFillColor:
                              "rgb(189, 196, 224) !important",
                          }}
                        >
                          {/* <InputLabel
                            id="deliveryLocation-label"
                            style={{
                              WebkitTextFillColor:
                                "rgb(189, 196, 224) !important",
                            }}
                          >
                            Delivery Location
                          </InputLabel> */}
                          <Select
                            variant="standard"
                            labelId="deliveryLocation-select-label"
                            id="deliverLocation-select"
                            // value={formik.values.deliveryLocation}
                            value={formik.values.deliveryLocation === "" ? "Select Delivery Location" : formik.values.deliveryLocation}
                            label="deliveryLocation"
                            name="deliveryLocation"
                            style={{
                              color: "rgb(189, 196, 224)",
                              margin: "0",
                              padding: "5px 5px 5px 10px",
                              fontSize: "14px",
                            }}
                            error={
                              formik.values.deliveryLocation === "" &&
                              Boolean(formik.errors.deliveryLocation)
                            }
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  height:"300px"
                                },
                              },
                            }}
                            //   onChange={(event,newvalue)=>{
                            //     formik.setFieldValue('deliveryLocation',event.target.value)
                            // }}
                            onChange={formik.handleChange}
                          >
                            <MenuItem
                            selected
                            disabled
                              value={`Select Delivery Location`}
                            >
                              Select Delivery Location
                            </MenuItem>
                            <MenuItem
                              value={`{"lat":"26.31088792094651", "lon":"50.13294742758049"}`}
                            >
                              NORTH_ADMIN_BLOCK / EAST_ADMIN_BLOCK
                            </MenuItem>
                            <MenuItem
                              value={`{"lat":"26.310638620389586", "lon":"50.13190923093649"}`}
                            >
                              SOUTH_ADMIN_BLOCK
                            </MenuItem>
                            <MenuItem
                              value={`{"lat":"26.311910798607546", "lon":"50.13107235227383"}`}
                            >
                              TOWER_BUILDING / BUILDING_730
                            </MenuItem>
                            <MenuItem
                              value={`{"lat":"26.312361755662216", "lon":"50.130194080774395"}`}
                            >
                              EXPEC_1
                            </MenuItem>
                            <MenuItem
                              value={`{"lat":"26.31198475309504", "lon":"50.12979609994164"}`}
                            >
                              EXPEC_2
                            </MenuItem>
                            <MenuItem
                              value={`{"lat":"26.312390130444207", "lon":"50.12828280111497"}`}
                            >
                              BUILDING_137
                            </MenuItem>
                            <MenuItem
                              value={`{"lat":"26.313131435706133", "lon":"50.128804545365476"}`}
                            >
                              BUILDING_9157
                            </MenuItem>
                            <MenuItem
                              value={`Custom`}
                            >
                              Custom
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      {formik.values.orderSize === "Custom" && (
                        <div className="AddFleet_form_field_wrapper addlocation_textfield form__control__wrapper">
                          <TextField
                            id="standard-size-normal"
                            fullWidth
                            variant="standard"
                            value={formik.values.height}
                            name="height"
                            type="number"
                            onChange={formik.handleChange}
                            style={{ fontSize: "14px" }}
                            placeholder="Height"
                            error={
                              formik.touched.height &&
                              Boolean(formik.errors.height)
                            }
                            required
                            helperText={
                              formik.touched.height && formik.errors.height
                            }
                          />
                          <TextField
                            id="standard-size-normal"
                            fullWidth
                            variant="standard"
                            value={formik.values.length}
                            name="length"
                            type="number"
                            onChange={formik.handleChange}
                            style={{ fontSize: "14px" }}
                            placeholder="Length"
                            error={
                              formik.touched.length &&
                              Boolean(formik.errors.length)
                            }
                            required
                            helperText={
                              formik.touched.length && formik.errors.length
                            }
                          />
                          <TextField
                            id="standard-size-normal"
                            fullWidth
                            variant="standard"
                            value={formik.values.width}
                            name="width"
                            type="number"
                            onChange={formik.handleChange}
                            style={{ fontSize: "14px" }}
                            placeholder="Width"
                            error={
                              formik.touched.width &&
                              Boolean(formik.errors.width)
                            }
                            required
                            helperText={
                              formik.touched.width && formik.errors.width
                            }
                          />
                          <TextField
                            id="standard-size-normal"
                            fullWidth
                            variant="standard"
                            value={formik.values.weight}
                            name="weight"
                            type="number"
                            onChange={formik.handleChange}
                            style={{ fontSize: "14px" }}
                            placeholder="Weight"
                            error={
                              formik.touched.weight &&
                              Boolean(formik.errors.weight)
                            }
                            required
                            helperText={
                              formik.touched.weight && formik.errors.weight
                            }
                          />
                        </div>
                      )}
                        {formik.values.deliveryLocation === "Custom" && (
                        <div className="AddFleet_form_field_wrapper addlocation_textfield form__control__wrapper">
                          <TextField
                            id="standard-size-normal"
                            fullWidth
                            variant="standard"
                            value={formik.values.longitutde}
                            name="longitutde"
                            type="number"
                            onChange={formik.handleChange}
                            style={{ fontSize: "14px" }}
                            placeholder="Longitutde"
                            error={
                              formik.touched.longitutde &&
                              Boolean(formik.errors.longitutde)
                            }
                            required
                            helperText={
                              formik.touched.longitutde && formik.errors.longitutde
                            }
                          />
                          <TextField
                            id="standard-size-normal"
                            fullWidth
                            variant="standard"
                            value={formik.values.latitude}
                            name="latitude"
                            type="number"
                            onChange={formik.handleChange}
                            style={{ fontSize: "14px" }}
                            placeholder="Latitude"
                            error={
                              formik.touched.latitude &&
                              Boolean(formik.errors.latitude)
                            }
                            required
                            helperText={
                              formik.touched.latitude && formik.errors.latitude
                            }
                          />
                        </div>
                      )}
                      <div className="Order_modal_button_wrapper">
                        <button type="submit" className="Order_save_button">
                          {" "}
                          Book{" "}
                        </button>
                        <button
                          onClick={orderModalClose}
                          className="Order_modal_cancel_button"
                        >
                          {" "}
                          Cancel{" "}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </Box>
          </Modal>
        </>
      )}

      <div className="Fleet_page_Whole_content_wrapper_main">
          <div className="over-scroll">
            <table
              style={{ width: "100%", marginLeft: "0px" }}
              className="Fleet_page_table_Main_whole orderlist_table"
            >
              <thead>
                <tr>
                  <th> Robot Id </th>
                  <th> OrderId </th>
                  <th> Status</th>
                  <th> Compartment</th>
                  <th> Delivery (Lat,Long)</th>
                  <th> PickUp (Lat,Long)</th>
                  <th> Order Info</th>
                  <th> Time Stamp </th>
                </tr>
              </thead>
              <tbody>
                {orderlistData?.map((item , index) => {
                  return (
                    <tr key={index}>
                      <td> {item.robotId}</td>
                      <td> {item.orderId}</td>
                      <td >
                        {" "}
                        {orderStatusColor(item.orderStatus , item.statusCode)}
                        
                      </td>
                      <td> {item.cabinId}</td>
                      <td>
                        {item?.delivery?.lat? item.delivery.lat.toFixed(8):''}{item?.delivery?.lat? ',':''}{"  "}
                        {item?.delivery?.lat? item.delivery.lon.toFixed(8):''}
                      </td>
                      <td>
                        {item?.pickup?.lat ? item.pickup.lat.toFixed(8): ''}{item?.pickup?.lat? ',':''}
                        {item?.pickup?.lon ? item.pickup.lon.toFixed(8):''}
                      </td>
                      <td>
                        {item.orderInfo?.dimension?.height  ? item.orderInfo.dimension.height:''}
                        {item.orderInfo?.dimension?.length  ?item.orderInfo.dimension.length : ''} {" "}
                        {item.orderInfo?.dimension?.width  ?item.orderInfo.dimension.width :""} {" "}
                        {item.orderInfo.size ? (
                          <>({item.orderInfo.size})</>
                        ) : null}
                      </td>
                      <td>
                        {" "}
                        {new Date(item.createdAt).toString().replace('GMT+0530 (India Standard Time)','')}
                        {/*{.replace('GMT+0530 (India Standard Time)','')}  */}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

          
          </div>

      </div>
      {Paginationlength ? (
        <Pagination
          className="PaginationWrapper"
          count={showcount}
          page={page}
          onChange={handleChangetwo}
          sx={{ width: "100%" }}
        />
      ) : null}
      <ToastContainer />
    </>
  );
}

export default OrderList;
