import logo from './logo.svg';
import './App.css';
import Login from './components/Login';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import FleetPage from './subcomponents/FleetPage';
import RobotPage from './subcomponents/RobotPage';
import IndividualFleet from './subcomponents/IndividualFleet';
import RobotIndividual from './subcomponents/RobotIndividual';
import AddFleet from './subcomponents/AddFleet';
import AddRobot from './subcomponents/AddRobot';
import AddUser from './subcomponents/AddUser';
import UserList from './subcomponents/UserList';
import EditFleet from './subcomponents/EditFleet';
import EditRobot from './subcomponents/EditRobot';
import EditUser from './subcomponents/EditUser';
import IndividualUsers from './subcomponents/IndividualUsers';
import OrderList from './subcomponents/OrderList';
import AddOrder from './subcomponents/AddOrder';
import ForgotPassword from './components/ForgotPassword';
import OTPVerification from './components/OTPVerification';
import ChangePassword from './components/ChangePassword';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Drawer, Link } from '@mui/material';
import LogOutModal from './subcomponents/LogOutModal';
function App() {

  const dashboardopen=true
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const firstName = localStorage.getItem('firstname')
  const lastName = localStorage.getItem('lastname')
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  return (
    <div className="App">
{/* 
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}> <MenuIcon/></Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
          <ul
          className={ " Dashboard_page_side_bar"}
        >
          <Link
            to="/"
            style={{ textDecoration: "none" }}
            className="Dashboard_side_bar_links"
          >
            <li className={props.active === "" ? "SideBar_active" : null}>
              <img
                style={{ width: "100%" }}
                src="/assets/images/OttonomyLogo.png"
                alt="logo"
              />
            </li>{" "}
          </Link>
          <Link
            to="/dashboard"
            style={{ textDecoration: "none" }}
            className="Dashboard_side_bar_links"
          >
            <li
              className={props.active === "dashboard" ? "SideBar_active" : null}
            >
              <span>
           
              </span>
              <p
                style={{ marginBottom: "0px" }}
                className="Dashboard_side_bar_P"
              >
                Dashboard
              </p>
            </li>{" "}
          </Link>
          <Link
            to="/fleetPage"
            style={{ textDecoration: "none" }}
            className="Dashboard_side_bar_links"
          >
            <li
              className={props.active === "fleetpage" ? "SideBar_active" : null}
            >
              <span style={{ width: "25%" }}>
  
              </span>
              <p
                style={{ marginBottom: "0px" }}
                className="Dashboard_side_bar_P"
              >
                {" "}
                Fleets
              </p>
            </li>
          </Link>
          <Link
            to="/robotpage"
            style={{ textDecoration: "none" }}
            className="Dashboard_side_bar_links"
          >
            <li
              className={props.active === "robotpage" ? "SideBar_active" : null}
            >
              <span style={{ width: "25%" }}>

              </span>
              <p
                style={{ marginBottom: "0px" }}
                className="Dashboard_side_bar_P"
              >
                {" "}
                Robots
              </p>
            </li>
          </Link>
        </ul>
        <div
          className={'name-log' }
       >
          {" "}
          <div>
            <p className="SideBar_name_p">{firstName} {lastName}</p>
            <p
              className="SideBar_name_p"
              style={{ opacity: "0.7", fontSize: "11px" }}
            >
              {" "}
              {role}
            </p>
            <LogOutModal />
          </div>
        </div>
          </Drawer>
        </React.Fragment>
      ))}     */}
     <Routes> 
        <Route path="/" element={<Login/>}/>
        <Route path="/dashboard" element={<Dashboard dashboardopen={dashboardopen}/>}/>
        <Route path="/fleetPage" element={<FleetPage dashboardopen={dashboardopen}/>}/>
        <Route path="/robotpage" element={<RobotPage dashboardopen={dashboardopen}/>}/>
        <Route path="fleetPage/:fleetId" element={<IndividualFleet dashboardopen={dashboardopen}/>}/>
        <Route path="robotPage/:robotId" element={<RobotIndividual dashboardopen={dashboardopen}/>}/>
        <Route path="/addfleet" element={<AddFleet dashboardopen={dashboardopen}/>}/>
        <Route path="/addrobot" element={<AddRobot dashboardopen={dashboardopen}/>}/>
        <Route path="/adduser" element={<AddUser dashboardopen={dashboardopen}/>}/>
        <Route path="/userlist" element={<UserList dashboardopen={dashboardopen}/>}/>
        <Route path="editfleet/:fleetId" element={<EditFleet dashboardopen={dashboardopen}/>}/>
        <Route path="editrobot/:robotId" element={<EditRobot dashboardopen={dashboardopen}/>}/>
        <Route path="edituser/:userid" element={<EditUser dashboardopen={dashboardopen}/>}/>
        <Route path="userlist/:userId" element={<IndividualUsers dashboardopen={dashboardopen}/>}/>
        <Route path="/orderlist" element={<OrderList dashboardopen={dashboardopen}/>}/>
        <Route path="/addorder" element={<AddOrder dashboardopen={dashboardopen}/>}/>
        <Route path="/forgotpassword" element={<ForgotPassword dashboardopen={dashboardopen}/>}/>
        <Route path="/otpverification" element={<OTPVerification dashboardopen={dashboardopen}/>}/>
        <Route path="/changepassword" element={<ChangePassword dashboardopen={dashboardopen}/>}/>
    </Routes> 
    </div>
  );
}

export default App;
